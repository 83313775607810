.toggleContainer {
  @apply flex flex-row w-full bg-white border-grey-light-darken-2 border relative justify-center items-center h-32 rounded-xxl overflow-hidden;
}

.toggleContainer .toggleOption {
  @apply text-xs inline-block relative text-center leading-none cursor-pointer text-blue-light z-1;
  transition: all 0.5s ease-in-out;
}

.toggleContainer .toggleOption.selected {
  @apply text-white;
}

.toggleContainer .toggle {
  @apply absolute flex h-full bottom-0 left-0 bg-pink;
  transition: all 250ms ease-out;
}

.optionDisabled {
  background: #e6e6e6;
  cursor: no-drop !important;
}
