@primary-color: #d932ce; // primary color for all components
@secondary-color: #068bbf;
@maastricht-blue-color: #011627;
@powder-color: #fdfffc;
@turqoise-color: #0bc4d9;

@link-color: #d932ce; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 16px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

@body-background: @powder-color;
@layout-footer-background: @maastricht-blue-color;

// Layout
@layout-body-background: #fff;
@layout-header-background: #001529;
@layout-header-height: 64px;
@layout-header-padding: 0 50px;
@layout-header-color: @text-color;
@layout-footer-padding: 24px 50px;
@layout-footer-background: @layout-body-background;
@layout-sider-background: @layout-header-background;
@layout-trigger-height: 48px;
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;
// Layout light theme
@layout-sider-background-light: #fff;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: @text-color;

@font-family: "Avenir LT W01_55 Roman1475520", Roboto, "Helvetica Neue", Arial,
  "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji";

@font-size-base: 16px;
@font-size-lg: @font-size-base * 1.33;
@font-size-sm: 12px;
// https://www.modularscale.com/?16&px&1.333
@heading-1-size: ceil(@font-size-base * 3.15); // 50px
@heading-2-size: ceil(@font-size-base * 2.37); // 37px
@heading-3-size: ceil(@font-size-base * 1.77); // 28px
@heading-4-size: ceil(@font-size-base * 1.33); // 21px

@btn-default-color: @turqoise-color;
@btn-default-bg: @component-background;
@btn-default-border: @turqoise-color;

html,
body {
  @apply overflow-x-hidden relative;
}

#fstvl,
#root {
  @apply overflow-x-hidden h-full relative;
}

.ant-layout-header.mast-head {
  @apply pb-8 h-auto fixed w-full z-10;

  &:after {
    @apply absolute bottom-0 h-8 w-full left-0;
    content: "";
    background-image: linear-gradient(90deg, #ee61e9 2%, #068bbf 97%);
  }
}

.ant-form-item-explain {
  @apply text-xs;
}

.ant-divider-horizontal {
  @apply h-4;
  // background-image: linear-gradient(90deg, #ee61e9 2%, #068bbf 97%);
}

.fstvl-divider-horizontal {
  background-image: linear-gradient(90deg, #ee61e9 2%, #068bbf 97%);
}

.fstvl-divider-horizontal--blue {
  background-image: linear-gradient(90deg, #0bc4d9 2%, #068bbf 97%);
}

.ant-alert {
  @apply mb-16;
}

.ant-layout-header {
  @apply p-0;
}

h1,
h1.ant-typography {
  font-size: ceil(@font-size-base * 1.575);
}

h2,
h2.ant-typography {
  font-size: ceil(@font-size-base * 1.383);
}

h3,
h3.ant-typography {
  font-size: ceil(@font-size-base * 1.138);
}

h4,
h4.ant-typography {
  font-size: ceil(@font-size-base);
}

.ant-typography {
  h1,
  h2,
  h3,
  h4,
  + h1.ant-typography,
  + h2.ant-typography,
  + h3.ant-typography,
  + h4.ant-typography {
    &.flush {
      @apply mt-0 mb-0;
    }

    &.flush-top {
      @apply mt-0;
    }

    &.flush-bottom {
      @apply mb-0;
    }
  }

  &.display-1,
  &.display-2 {
    font-family: "Avenir LT W01_95 Black1475556";
  }

  &.flush {
    @apply mt-0 mb-0;
  }

  &.flush-top {
    @apply mt-0;
  }

  &.flush-bottom {
    @apply mb-0;
  }

  &.display-1 {
    &:extend(h1);
    @apply tracking-tight leading-none;
    font-size: ceil(@font-size-base * 5.61); // 89px
    font-weight: 900;

    &.text-blue-light {
      @apply text-blue-light;
    }

    &.flush {
      @apply mt-0 mb-0;
    }

    &.flush-top {
      @apply mt-0;
    }

    &.flush-bottom {
      @apply mb-0;
    }
  }

  &.display-2 {
    &:extend(.h1);
    @apply tracking-tight leading-tight;
    font-size: ceil(@font-size-base * 4.209);
    font-weight: 900;

    &.flush {
      @apply m-0;
    }

    &.flush-top {
      @apply mt-0;
    }

    &.flush-bottom {
      @apply mb-0;
    }
  }

  &.body-1 {
    &:extend(.h1);
    @apply tracking-tight leading-tight;
    font-size: ceil(@font-size-base);

    &.flush {
      @apply m-0;
    }

    &.flush-top {
      @apply mt-0;
    }

    &.flush-bottom {
      @apply mb-0;
    }
  }

  &.body-2 {
    &:extend(.h1);
    @apply tracking-tight leading-tight;
    font-size: ceil(@font-size-base * 0.75);

    &.flush {
      @apply m-0;
    }

    &.flush-top {
      @apply mt-0;
    }

    &.flush-bottom {
      @apply mb-0;
    }
  }
}

.mapboxgl-map {
  @apply border border-grey-light-lighten-2 rounded overflow-hidden;
}

.mapboxgl-ctrl-attrib-inner {
  @apply py-4 px-10;

  a {
    @apply text-xs opacity-50;
  }
}
