@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=63bcee36-e2ea-4bcb-8da7-c5bbe9bd9bca");
@font-face {
  font-family: "Avenir LT W01_55 Roman1475520";
  src: url("/fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"),
    url("/fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff");
}
@font-face {
  font-family: "Avenir LT W01_95 Black1475556";
  src: url("/fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),
    url("/fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff");
}
