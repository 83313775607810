/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
  padding: 12px 2.3em 12px 8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 25px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 25px;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #fafafa;
}
.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-wrapper {
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  position: relative;
  z-index: 0;
  clear: both;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e6f7ff;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #dcf4ff;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table td {
  background: transparent;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination-left {
  float: left;
}
.ant-table-pagination-center {
  text-align: center;
}
.ant-table-pagination-right {
  float: right;
}
.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: #f7f7f7;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}
.ant-table-column-sorters {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px 16px;
}
.ant-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf;
}
.ant-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}
.ant-table-column-sorter-inner {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  display: inline-block;
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #1890ff;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-filter-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: -16px -16px;
}
.ant-table-filter-column-title {
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  padding: 16px 2.3em 16px 16px;
}
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
  margin: 0;
}
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 2.3em 0 0;
}
.ant-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: #e5e5e5;
}
.ant-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-filter-trigger.active {
  color: #1890ff;
}
.ant-table-filter-dropdown {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table .ant-table-selection-col {
  width: 60px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-selection {
  position: relative;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-table-selection-extra .anticon {
  display: inline-block;
  font-size: 10px;
  color: #bfbfbf;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #1890ff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 12px;
  vertical-align: -2px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #40a9ff;
}
.ant-table-row-expand-icon:active {
  color: #096dd9;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  -webkit-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
          box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  -webkit-box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
          box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  -webkit-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
          box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  -webkit-box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
          box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 2px 2px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination {
  float: left;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  float: left;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  float: right;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-center {
  float: initial;
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 8px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger-container {
  right: auto;
  left: 0;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-selection-extra {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
